import './App.css';
import MainBanner from './screen/MainBanner';

function App() {
  return (
    <div className="App">
      <MainBanner/>
    </div>
  );
}

export default App;
